<script lang="ts" setup>
import { computed } from 'vue'
import { searchResults, searchTerm } from '@/services/search'
import { useStore } from '@nanostores/vue'

interface Props {
  displayGroups: any[]
}

const props = defineProps<Props>()
const search = useStore(searchTerm)
const results = useStore(searchResults)
const isSearch = computed(() => {
  return !!search.value && !!results.value
})

const displayedGroups = props.displayGroups.filter(
  (displayGroup) => displayGroup.emoji && displayGroup.emoji !== '🏠',
)
</script>

<style>
#nav-categories.fixed {
  max-width: calc(72rem - 4px);
  top: 66px;
}
</style>

<template>
  <div
    v-if="!isSearch"
    id="nav-categories"
    class="grid grid-rows-2 grid-flow-col gap-x-2.5 gap-y-1 overflow-x-auto px-4 py-2 w-full z-30 bg-white md:max-w-6xl select-none hide-scrollbar relative"
  >
    <a
      v-for="displayGroup in displayedGroups"
      class="flex flex-col items-center justify-start w-items-4.5 md:w-24 h-20 gap-1 select-none"
      draggable="false"
      :href="`#${displayGroup.slug}`"
    >
      <span class="rounded-full bg-slate-100 px-2 py-1 text-center text-xl select-none">
        {{ displayGroup.emoji }}
      </span>
      <span
        class="text-center leading-tight text-slate-500 select-none line-clamp-3"
        style="font-size: 0.7rem"
      >
        {{ displayGroup.name.replace(displayGroup.emoji, '').trim() }}
      </span>
    </a>
  </div>
</template>
